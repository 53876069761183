/* eslint-disable import/no-unused-modules */
import useUtils from '@hooks/useUtils';
import {
  Box,
  IconButton,
  Link as MuiLink,
  Tooltip,
  Typography,
} from '@mui/material';
import { TProject } from '@typescript/models/Project.model';
import { TradeType } from '@typescript/models/TradeTransaction.model';
import {
  calculateProfitLoss,
  cryptoFormat,
  formatNr,
  sliceWalletAddress,
  toRem,
} from '@utils/functions';
import { LinkStyled } from '@styles/shared/SLinks';
import useUser from '@hooks/useUser';
import moment from 'moment';
import { TListing } from '@typescript/models/Listing.model';
import { Svgs } from '@utils/svgs';
import TimeField from '@components/activity/TimeField';
import TableStatuses from '@components/shared/TableStatuses';
import { ListingTradeStatus, OfferTradeStatus } from '@constants/CDataStatus';
import { TOffer } from '@typescript/models/Offer.model';

export const tradeTypeCell = (type: TradeType) => {
  return {
    valueGetter: type,
    renderCell: <Typography sx={{ fontSize: toRem(16) }}>{type}</Typography>,
  };
};

export const listingTypeCell = (listing: TListing) => {
  const typeName = listing.type === 1 ? 'SELL' : ' BUY';
  return {
    valueGetter: typeName,
    renderCell: (
      <Typography sx={{ fontSize: toRem(16) }}>{typeName}</Typography>
    ),
  };
};

export const projectCell = (project: TProject) => {
  return {
    valueGetter: project.name,
    renderCell: (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={project.image}
          width={40}
          height={40}
          style={{ borderRadius: '6px', marginRight: '0.5rem' }}
          alt=""
        />
        <MuiLink
          href={`/projects/${project.slug}`}
          sx={{
            color: 'primary.main',
            marginLeft: '0.5rem',
            fontSize: '14px',
            cursor: 'pointer',
            textDecoration: 'underline',
            ':hover': {
              textDecoration: 'none',
            },
          }}
        >
          {project.name}
        </MuiLink>
      </Box>
    ),
  };
};

export const tokenAmountCell = (tokenAmount: number) => {
  return {
    valueGetter: formatNr(tokenAmount, undefined, 0),
    renderCell: (
      <Tooltip title={tokenAmount} placement="top-start">
        <Typography sx={{ fontSize: '14px' }}>
          {formatNr(tokenAmount, undefined, 0)}
        </Typography>
      </Tooltip>
    ),
  };
};

export const performanceCell = (performance: number) => {
  return {
    valueGetter: performance,
    renderCell: (
      <Typography
        sx={{
          color: performance >= 0 ? 'success.main' : 'error.main',
        }}
        variant="h5"
      >
        {performance >= 0 && '+'}
        {formatNr(performance, undefined, 2)}%
      </Typography>
    ),
  };
};

export const tokenValueCell = (tokenValue: number) => {
  return {
    valueGetter: formatNr(tokenValue, true, 0),
    renderCell: (
      <Typography sx={{ fontSize: '14px' }}>
        {formatNr(tokenValue, true, 0)}
      </Typography>
    ),
  };
};

export const currentTokenPriceCell = (currentPrice: number) => {
  return {
    valueGetter: currentPrice,
    renderCell: (
      <Tooltip title={currentPrice} placement="top-start">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {cryptoFormat(currentPrice)}
        </Box>
      </Tooltip>
    ),
  };
};

export const totalCostCell = (value: number) => {
  return {
    valueGetter: value,
    renderCell: (
      <Tooltip title={value} placement="top-start">
        <Typography sx={{ fontSize: toRem(16) }}>
          {formatNr(value, true, 0)}
        </Typography>
      </Tooltip>
    ),
  };
};

export const confirmationCell = (confirmed: boolean) => {
  return {
    valueGetter: confirmed,
    renderCell: (
      <Tooltip
        placement="top-start"
        title={
          confirmed
            ? 'Your transaction is completed.'
            : 'Your transaction is waiting for confirmation'
        }
      >
        <Typography
          sx={{
            fontSize: toRem(16),
            color: confirmed ? 'success.main' : 'error.main',
          }}
        >
          {confirmed ? 'YES' : 'NO'}
        </Typography>
      </Tooltip>
    ),
  };
};

export function WalletCell({ wallet }: { wallet: string }) {
  const { getBscAddressLink } = useUtils();
  const { currentUser } = useUser();

  const fromEdited = sliceWalletAddress(wallet);
  const link = getBscAddressLink(wallet);
  return (
    <LinkStyled href={link} target="_blank">
      {currentUser?.ethAddress === wallet ? 'You' : fromEdited}
    </LinkStyled>
  );
}

export function TimeCell({
  hash,
  createdAt,
}: {
  hash: string;
  createdAt: string;
}) {
  const { getBscTxLink } = useUtils();

  return (
    <LinkStyled
      target="_blank"
      href={getBscTxLink(hash)}
      sx={{ marginRight: '0.3rem' }}
    >
      {moment(createdAt).fromNow()}
    </LinkStyled>
  );
}

export const feeCell = (percentage: number) => {
  return {
    valueGetter: percentage,
    renderCell: (
      <Tooltip title={percentage} placement="top-start">
        <Typography sx={{ fontSize: toRem(16) }}>
          {formatNr(percentage)}%
        </Typography>
      </Tooltip>
    ),
  };
};

export const askingPriceCell = (listing: TListing) => {
  return {
    valueGetter: listing.tokenPrice,
    renderCell: (
      <Tooltip title={listing.tokenPrice} placement="top-start">
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
          {cryptoFormat(listing.tokenPrice, { fontSize: toRem(16) })}
          {calculateProfitLoss(
            listing.project.currentPrice,
            listing.tokenPrice,
            { fontSize: toRem(16) },
          )}
        </Box>
      </Tooltip>
    ),
  };
};

export const offerPriceCell = (offer: TOffer) => {
  return {
    valueGetter: offer.offeredTokenPrice,
    renderCell: (
      <Tooltip title={offer.offeredTokenPrice} placement="top-start">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {cryptoFormat(offer.offeredTokenPrice, {
            fontSize: toRem(16),
          })}
        </Box>
      </Tooltip>
    ),
  };
};

export const offeredPriceCell = (listing: TListing) => {
  const { activeOfferTokenPrice: offerTokenPrice } = listing.offersStats;

  return {
    valueGetter: offerTokenPrice,
    renderCell: (
      <Box display="flex" justifyContent="center" gap="8px">
        <Tooltip title={offerTokenPrice} placement="top-start">
          <Box>
            {offerTokenPrice === 0
              ? '-'
              : cryptoFormat(+offerTokenPrice, {
                  fontSize: toRem(16),
                })}
          </Box>
        </Tooltip>
        {offerTokenPrice !== 0 &&
          calculateProfitLoss(listing.project.currentPrice, +offerTokenPrice, {
            fontSize: toRem(16),
          })}
      </Box>
    ),
  };
};

export const totalOfferCostCell = (listing: TListing) => {
  const { activeMaxOfferedPrice: offeredPrice } = listing.offersStats;

  return {
    valueGetter: offeredPrice,
    renderCell: (
      <Tooltip title={offeredPrice} placement="top-start">
        <Typography sx={{ fontSize: toRem(16) }}>
          {offeredPrice === 0 ? '-' : formatNr(+offeredPrice, true, 0)}
        </Typography>
      </Tooltip>
    ),
  };
};

export const negotiableCell = (listing: TListing) => {
  return {
    valueGetter: listing.negotiable ? 'Yes' : 'No',
    renderCell: (
      <Typography sx={{ fontSize: toRem(16) }}>
        {listing.negotiable ? 'Yes' : 'No'}
      </Typography>
    ),
  };
};

export function SocialCell({ listing }: { listing: TListing }) {
  const copyUserName = async (username: string) => {
    await navigator.clipboard.writeText(username);
  };

  const { discordName, telegramLink } = listing;

  if (
    (discordName && discordName.length) ||
    (telegramLink && telegramLink.length)
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        {telegramLink && !!telegramLink.length && (
          <LinkStyled href={telegramLink} target="_blank">
            <Box
              sx={{
                color: 'primary.dark',
                '&:hover': {
                  color: 'text.primary',
                },
              }}
            >
              <Svgs.TelegramIcon
                color="inherit"
                style={{
                  marginTop: '4px',
                }}
              />
            </Box>
          </LinkStyled>
        )}
        {discordName && !!discordName.length && (
          <Tooltip title="Copy">
            <IconButton
              onClick={() => copyUserName(discordName || '')}
              sx={{
                p: 0,
                color: 'primary.dark',
                '&:hover': {
                  color: 'text.primary',
                },
              }}
            >
              <Svgs.DiscordIcon
                color="inherit"
                style={{
                  cursor: 'pointer',
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }
  return <Box>-</Box>;
}

export const expireInCell = (listing: TListing) => {
  return {
    valueGetter: new Date(listing.expireAt).getTime(),
    renderCell: (
      <TimeField
        key={listing.id}
        sx={{ fontSize: toRem(16) }}
        listing={listing}
      />
    ),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isListingTradeStatus(status: any): status is ListingTradeStatus {
  return Object.values(ListingTradeStatus).includes(status);
}

export function ListingStatusCell({ listing }: { listing: TListing }) {
  const { tradeStatus } = listing;

  if (tradeStatus) {
    if (isListingTradeStatus(tradeStatus))
      return (
        <TableStatuses
          listingStatus={tradeStatus}
          offerStatus={null}
          sx={{ fontSize: toRem(16) }}
        />
      );
    return (
      <TableStatuses
        listingStatus={null}
        offerStatus={tradeStatus}
        sx={{ fontSize: toRem(16) }}
      />
    );
  }
  return null;
}

export function OfferStatusCell({
  offer,
  inactive,
}: {
  offer: TOffer;
  inactive?: boolean;
}) {
  if (inactive) {
    return (
      <TableStatuses
        listingStatus={null}
        offerStatus={OfferTradeStatus.OFFER_EXPIRED}
      />
    );
  }
  if (offer.tradeStatus)
    return (
      <TableStatuses
        listingStatus={null}
        offerStatus={offer.tradeStatus}
        sx={null}
      />
    );

  return null;
}
